/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';

const UIInputContainer = styled.label`
  position: relative;
  width: 100%;

  .text-label {
    font-weight: 400;
    font-size: 13px;
    color: #222;
    line-height: normal;
    margin-bottom: 8px;
  }

  & input,
  textarea {
    border: 1px solid #e4e5e8;
    height: 48px;
    font-size: 16px;
    line-height: normal;
    color: #000;
    padding: 4px 0.75rem;
    border-radius: 4px;
    transition: 0.2s;
    width: 100%;
    ${(p) => (p.fullwidth ? 'width: 100% !important' : '')}

    &.small {
      height: 40px;
      line-height: 24px;
    }

    &[disabled] {
      opacity: 0.75;
      background-color: #f0f3f6;
    }

    &:hover,
    &:focus {
      border-color: #aaa;
      outline: 0;
    }
  }
`;

class Input extends Component {
  state = {
    val: this.props.value || this.props.defaultValue || '',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value || nextProps.defaultValue) {
      this.setState({
        val: nextProps.value || nextProps.defaultValue,
      });
    }
  }

  onChange = (e, onChange) => {
    this.setState({ val: e.target.value });
    if (onChange) onChange(e);
  };

  render() {
    const {
      textLabel,
      innerRef,
      onChange,
      children,
      containerStyle,
      fullwidth,
      ...rest
    } = this.props;
    const { val } = this.state;
    return (
      <UIInputContainer style={containerStyle}>
        <p className="text-label">{textLabel}</p>
        <input {...rest} ref={innerRef} onChange={(e) => this.onChange(e, onChange)} value={val} />
        {children}
      </UIInputContainer>
    );
  }
}
export default Input;
