/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from 'layout';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';
import CustomersRate, { Rate } from 'components/CustomersRate';
import checkmark from 'img/ui/checkmarkGreen.svg';
import Input from 'components/signup/signupInput';
import Button from 'components/ui/button';
import { post } from 'api';

const Section = styled.section`
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 434px;
  grid-template-rows: auto auto;
  grid-column-gap: 64px;
  grid-row-gap: 70px;
  margin-bottom: 62px;
  position: relative;
  & h1 {
    margin-right: 16px;
  }
  & ol {
    padding-inline-start: 20px;
  }
  & ${Rate} {
    grid-template-rows: 1fr;
    grid-row-gap: 0;
    margin: 0;
    justify-content: flex-start;
    grid-column-gap: 16px;
    a:hover {
      box-shadow: none;
    }
    & a:first-of-type {
      padding-left: 0;
    }
  }
  ${media.desktopWide} {
    margin-top: 3rem;
    grid-template-columns: 4fr 3fr;
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-column-gap: 0;
    grid-row-gap: 40px;
    & ${Rate} {
      justify-content: center;
      & a:first-of-type {
        padding-left: 26px;
      }
    }
  }

  ${media.mobile} {
    margin-top: 2rem;
    & form {
      width: calc(100% - 20px);
    }
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

const Container = styled.div`
  ${media.desktopWide} {
    margin-bottom: 3rem;
  }
  ${media.mobile} {
    margin-bottom: 2rem;
    width: calc(100% - 30px);
    & h1.line {
      & span {
        &::after {
          display: none;
        }
      }
    }
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  line-height: 34px;
  margin-top: 32px;
  ${media.mobile} {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 56px;
  padding-left: 42px;
  & li {
    font-size: 18px;
    line-height: 23px;
    margin-top: 16px;
    font-weight: 700;
    color: #000;
    position: relative;
    & span {
      position: relative;
      display: inline;
      font-weight: 700;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        background-color: #ffca00;
        height: 35%;
        z-index: -1;
        transition: 0.2s;
      }
      &:hover {
        &::after {
          height: 100%;
        }
      }
    }
    &::before {
      content: '';
      background-image: url(${checkmark});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-42px, -50%);
      width: 18px;
      height: 14px;
    }
  }
`;

const ListDesc = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 24px;
  margin-top: 40px;
`;

const InputContainer = styled.div`
  margin-top: 24px;
`;

const Textarea = styled.textarea`
  border: 1px solid #e4e5e8;
  min-height: 96px;
  font-size: 16px;
  line-height: normal;
  color: #000;
  padding: 8px 0.75rem;
  border-radius: 4px;
  transition: 0.2s;
  width: 100%;
  outline: 0;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  color: #222;
  line-height: normal;
  margin-bottom: 8px;
`;

const Message = styled.div`
  width: 100%;
  background-color: #e8fff2;
  border: 2px solid #00c853;
  padding: 11px 0;
  top: 60px;
  left: 0;
  text-align: center;
  grid-area: 1 / 1 / 2 / 4;
  &.error {
    border-color: rgb(240, 65, 52);
    background-color: #ffccc7;
  }
  & p {
    font-size: 18px;
    line-height: 34px;
    margin: 0;
  }
  ${media.desktop} {
    grid-area: 1 / 1 / 2 / 2;
    width: calc(100% - 24px);
  }
  ${media.mobile} {
    top: 50px;
    & p {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;

const Demo = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [traffic, setTraffic] = useState('100k-500k');
  const [help, setHelp] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [requestSended, setRequestSended] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const requestBody = {
      email,
      name,
      company,
      company_url: companyUrl,
      traffic,
      help,
    };
    post(`${process.env.GATSBY_API_URL}/accounts/demo`, requestBody)
      .then(() => {
        setMsg([
          <p>
            Thank you! We will contact you within <strong>1 business day</strong> to schedule a
            demo.
          </p>,
          'success',
        ]);
        setLoading(false);
        setRequestSended(true);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        let message = '';
        if (err.response !== undefined) {
          message = err?.response?.data?.error?.message;
        } else {
          message = <p>Unknown error, try again later.</p>;
        }
        setMsg([message, 'error']);
        console.log(err);
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };
  return (
    <Layout
      metaTitle="Book a Free Demo |  Top Session Replay Software"
      canonical="/demo/"
      metaDescription="Schedule a demo of LiveSession and let us show you how we can help your business grow. Looking for an enterprise pricing? Let's talk!"
    >
      <div className="container">
        <Section>
          {msg[0] && (
            <Message className={msg[1]}>
              {typeof msg[0] === 'string' ? <p>{msg[0]}</p> : msg[0]}
            </Message>
          )}
          <Container>
            <h1 className="line">
              Request a LiveSession <span>demo today</span>
            </h1>
            <SubTitle>
              If you are wondering how LiveSession can help you get to know your visitors better,
              schedule an in-depth product demo and get an offer tailored to your needs.
            </SubTitle>
            <ListDesc>What you will get on of this demo:</ListDesc>
            <List>
              <li>Understand how to get most of qualitative analytics</li>
              <li>Knowledge about LiveSession’s power features</li>
              <li>An extended trial period and enterprise features</li>
              <li>An offer tailored to your needs</li>
              <li>
                Access to the data <span>that matters</span> faster
              </li>
            </List>
            <CustomersRate black deleteTitle addStars />
          </Container>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Input
                textLabel="Your full name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                maxLength="255"
                required
                autoFocus
              />
            </InputContainer>
            <InputContainer>
              {' '}
              <Input
                textLabel="Business email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                minLength="5"
                maxLength="255"
                required
              />
            </InputContainer>
            <InputContainer>
              <Input
                textLabel="Company name"
                type="text"
                onChange={(e) => setCompany(e.target.value)}
                maxLength="512"
                required
              />
            </InputContainer>
            <InputContainer>
              <Input
                textLabel="Company URL"
                type="text"
                onChange={(e) => setCompanyUrl(e.target.value)}
                required
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="monthly-traffic">Monthly traffic</Label>
              <Textarea
                as="select"
                id="monthly-traffic"
                onChange={(e) => setTraffic(e.target.value)}
                style={{ minHeight: 48, backgroundColor: 'transparent' }}
                required
                defaultValue="100k-500k"
              >
                <option value="100k">Less than 100 000 sessions</option>
                <option value="100k-500k">100 000 - 500 000</option>
                <option value="500k-1mln">500 000 - 1 000 000</option>
                <option value="1mln-5mln">1 000 000 - 5 000 000</option>
                <option value="5mln+">5 000 000+</option>
              </Textarea>
            </InputContainer>
            <InputContainer style={{ marginBottom: 48 }}>
              <Label htmlFor="textarea">How can we help?</Label>
              <Textarea id="textarea" onChange={(e) => setHelp(e.target.value)} required />
            </InputContainer>

            <Button
              as="button"
              style={{ width: '100%' }}
              type="submit"
              disabled={loading || requestSended}
            >
              Book a demo
            </Button>
          </form>
        </Section>
      </div>
    </Layout>
  );
};

export default Demo;
